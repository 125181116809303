<template>
  <InputSearch
    ref="inputAviso"
    :route="route"
    placeholder='Digite o aviso que deseja procurar os materiais...'
    v-model="filters.aviso"
    :filters="filters"
    @update="updateModel"
  />
</template>

<script>
import InputSearch from '@/components/Form/InputSearch';

export default {
  name: 'inputAviso',

  components: {
    InputSearch,
  },

  data() {
    return {
      filters: {},
    };
  },

  methods: {
    updateModel(v) {
      this.$emit('updateModel', v);
    },
  },

  props: {
    route: {
      type: String,
    },
  },
};
</script>
