<template>
  <div>
    <cardless-base>
      <PageTitle :title="`Reimpressão de etiquetas`" />
      <b-row>
        <b-col cols="4">
          <b-form-group>
            <b-form-select
              :options="selectOptions"
              :value="selected"
              class="invision-input"
              @input="(v) => handleChangeOptions(v)"
            >
              <template #first>
                <option :value="null">
                  -- Por favor, selecione uma opção --
                </option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col v-if="selected">
          <component
            class="mb-4"
            v-for="(input) in tiposReimpressao[selected].inputs"
            :is="input.component"
            :key="`input-${input.key}`"
            :route="input.route"
            :item="item"
            @updateModel="updateModel"
          />
        </b-col>
      </b-row>
      <template-base>
        <div class="base-header mb-4">
          <b-row>
            <b-col>
              <span class="text">Últimos cadastros em lote</span>
            </b-col>
            <b-col v-if="selected !== 'etiqueta_material'">
              <GenericPrint
                :hasNoSelecetdMaterials="idsMaterialToPrint.length === 0 && idsMaterialGenericoToPrint.length === 0"
                class="printer-button"
                v-b-tooltip.hover
                title="Imprimir"
                :tipo="item ? item.tipo : ''"
                :data="{
                  createdAt: createdAtReference,
                  idsMaterial: idsMaterialToPrint,
                  idsProcessoCiclo: idsProcessoCicloToPrint,
                  id_material_generico: idsMaterialGenericoToPrint,
                  reimpressaoData,
                }"
                :hasReimpressaoDataByDefault="item ? item.hasReimpressaoDataByDefault : false"
              />
            </b-col>
            <b-col v-else class="printer-button">
              <printer-icon
                class="w20px pointer align-middle"
                @click="showModalReport()"
              />
              <Modal
                id="modalEtiquetaMaterial"
                ref="modalEtiquetaMaterial"
                name="modalEtiquetaMaterial"
                key="modalEtiquetaMaterial"
                hide-footer
                no-close-on-esc
                no-close-on-backdrop
                @hidden="closingModal"
                :hideXClose="handlerXButton"
              >
                <Printer
                  :resultData="itensToPrint"
                  buttonName="IMPRIMIR"
                  hideSection="section"
                  hideContent="content"
                  :isMaterialTag="true"
                  @successSend="successZPL"
                  @wants-exit="wantsExit"
                  @show-xclose="handleXButton"
                  :indexMaterial="indexMaterial"
                >
                  <template #printContent class="w">
                    <EtiquetaMaterial
                      :relatorioData="itensToPrint"
                      :indexMaterial="indexMaterial"
                    />
                  </template>

                </Printer>
              </Modal>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          :items="materiais"
          :fields="fields"
        >
          <b-spinner v-if="isBusy" class="align-middle" />
          <template #head(checkAll)>
            <b-form-checkbox
              @change="checkOrUncheckAll"
              :indeterminate="indeterminate"
              :checked="allChecked"
            />
          </template>
          <template #cell(checkAll)="{ item, index }">
            <b-form-checkbox
              :checked="item.checked"
              @change="addOrRemoveNewItemToPrint(item, $event, index)"
            >
            </b-form-checkbox>
          </template>
        </b-table>
      </template-base>
    </cardless-base>
  </div>
</template>

<script>
import moment from 'moment';
import genericRequest from '@/services/genericRequest';
import TemplateBase from '@/templates/Base';
import PageTitle from '@/templates/PageTitle';
import CardlessBase from '@/templates/CardlessBase';
import Printer from '@/components/Utils/Printer';
import Modal from '@/components/Utils/Modal';
import InputSearch from '@/components/Form/InputSearch';
import InputMaterialIdentificacao from './components/InputMaterialIdentificacao';
import InputAviso from './components/InputAviso';
import InputLote from './components/InputLote';
import GenericPrint from '@/components/Utils/GenericPrint';
import Button from '@/components/Utils/Button';
import EtiquetaMaterial from './Etiqueta';

export default {
  components: {
    TemplateBase,
    PageTitle,
    CardlessBase,
    Printer,
    Modal,
    InputSearch,
    InputMaterialIdentificacao,
    InputAviso,
    InputLote,
    GenericPrint,
    Button,
    EtiquetaMaterial,
  },

  props: {
    buttonName: {
      type: String,
      default: 'Imprimir',
    },
  },

  computed: {
    indeterminate() {
      const itensWithCheck = this.materiais.filter((el) => el.checked);
      return (
        itensWithCheck.length < this.materiais.length
        && itensWithCheck.length > 0
      );
    },

    allChecked() {
      return !this.materiais.some((el) => !el.checked);
    },

    isMaterialTag() {
      if (this.selected !== 'material') return false;
      if (this.materiais[0] && this.materiais[0].isMaterialTag) return true;
      return false;
    },

    item() {
      return this.tiposReimpressao[this.selected];
    },

    createdAtReference() {
      return this.materiais.length ? this.materiais[0].date : null;
    },
  },

  data() {
    return {
      idOrCode: null,
      fields: [
        {
          key: 'checkAll',
        },
        {
          key: 'tipoMaterial',
          label: 'Tipo de material',
        },
        {
          key: 'shownId',
          label: 'ID',
        },
        {
          key: 'descricao',
          label: 'Descrição',
        },
        {
          key: 'usuario',
          label: 'Colaborador',
          formatter: (value) => value || '-',
        },
        {
          key: 'lote',
          label: 'Lote',
          formatter: (value) => value || '-',
        },
        {
          key: 'date',
          label: 'Data',
          formatter: (value) => moment(value).format('DD/MM/YY HH:mm'),
        }, {
          key: 'aviso',
          formatter: (value) => value || '-',
        },
      ],
      idsMaterialToPrint: [],
      idsProcessoCicloToPrint: [],
      idsMaterialGenericoToPrint: [],
      materiais: [],
      selected: null,
      selectOptions: [],
      tiposReimpressao: [],
      isBusy: false,
      previousReimpressaoData: null,
      reimpressaoData: null,
      reportsMateriais: [],
      indexMaterial: 0,
      handlerXButton: true,
      itensToPrint: {},
    };
  },

  async created() {
    try {
      this.isBusy = true;
      this.tiposReimpressao = await genericRequest.getWithoutPagination(
        {},
        'tipoReimpressao',
      );

      this.updateSelectOptions();
      this.isBusy = false;
    } catch (error) {
      this.isBusy = false;
      let errorMessage = 'Ocorreu um problema ao recuperar os tipos de reimpressão';
      if (
        error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
      ) {
        errorMessage = error.response.data.error.errorMessage;
      }

      swal({
        icon: 'error',
        text: errorMessage,
        buttons: {
          confirm: 'OK',
        },
      });
    }
  },

  methods: {
    setDisabledInputs() {
      const filterInputKeys = Object.keys(this.$refs).filter((key) => key.includes('filter_'));
      if (filterInputKeys) {
        const refs = this.$refs;
        const filterInputs = filterInputKeys.map((key) => {
          const input = refs[key];
          return { name: key.substr(7), input };
        });
        const disabledInputs = filterInputs.map((element) => element.input.disabled && element)
          .filter((input) => typeof input !== 'boolean');
        this.disabledInputs = disabledInputs;
        return disabledInputs;
      }
      this.disabledInputs = [];
      return [];
    },
    updateSelectOptions() {
      this.selectOptions = Object.values(this.tiposReimpressao).map((tipo) => ({ value: tipo.value, text: tipo.text }));
    },

    handleChangeOptions(value) {
      this.selected = value;
      this.materiais = [];
      this.idsMaterialToPrint = [];
      this.idsProcessoCicloToPrint = [];
      this.idsMaterialGenericoToPrint = [];
      this.reportsMateriais = [];
    },

    updateModel(value) {
      if (value.materiais) {
        this.materiais = value.materiais.map((m) => ({
          ...m,
          date: m.date,
        }));
        this.previousReimpressaoData = value.reimpressaoData
          ? value.reimpressaoData[this.selected]
          : {
            reportsMateriais: [],
            reportProcesso: {},
            reportType: {},
          };
      } else if (value.length) {
        this.materiais = value.map((elem) => ({
          ...elem.material,
          tipoMaterial: elem.material.id_material
            ? elem.material.tipoMaterial.nome : elem.material.tipoMaterialGenerico.nome,
          shownId: elem.material.id || '-',
          id_material_generico: elem.material.tipoMaterialGenerico ? elem.material.id_material_generico : '-',
          ...elem,
          date: elem.createdAt,
        }));
      }
    },

    searchMaterialsByLoteFilters(v) {
      this.materiais = v.map((elem) => (
        { ...elem ? elem.materiais : elem.materiaisGenericos, dateTime: elem.dateTime }));
      this.idsMaterialToPrint = [];
      this.idsProcessoCicloToPrint = [];
      this.idsMaterialGenericoToPrint = [];
    },

    checkOrUncheckAll(event) {
      this.materiais = this.materiais.map((el) => ({
        ...el,
        checked: event,
      }));
      if (event) {
        this.idsMaterialToPrint = this.materiais.map((material) => material.id_material);
        this.idsProcessoCicloToPrint = this.materiais.map((material) => material.id_processo_ciclo);
        this.idsMaterialGenericoToPrint = this.materiais.map((material) => material.id_material_generico);
        this.reimpressaoData = {
          ...this.previousReimpressaoData,
        };
      } else {
        this.idsMaterialToPrint = [];
        this.idsProcessoCicloToPrint = [];
        this.idsMaterialGenericoToPrint = [];
        this.reimpressaoData = {
          ...this.previousReimpressaoData,
          reportsMateriais: [],
        };
      }
    },

    addOrRemoveNewItemToPrint(item, event, indexOfItem) {
      this.materiais = [
        ...this.materiais.slice(0, indexOfItem),
        {
          ...this.materiais[indexOfItem],
          checked: event,
        },
        ...this.materiais.slice(indexOfItem + 1),
      ];

      if (item && event) {
        if (item.id_material) {
          this.idsMaterialToPrint.push(item.id_material);
          this.idsProcessoCicloToPrint.push(item.id_processo_ciclo);
        } else {
          this.idsMaterialGenericoToPrint.push(item.id_material_generico);
          this.idsProcessoCicloToPrint.push(item.id_processo_ciclo);
        }
        this.reportsMateriais.push(
          this.previousReimpressaoData.reportsMateriais
            .find((report) => report.material.id_material === item.id_material
            || (report.material.id_material_generico
            && report.material.id_material_generico === item.id_material_generico)),
        );

        this.reimpressaoData = {
          ...this.previousReimpressaoData,
          reportsMateriais: [...this.reportsMateriais],
        };
      } else {
        let index;
        if (item.id_material) {
          index = this.idsMaterialToPrint.findIndex(
            (id) => id === item.id_material,
          );
        } else {
          index = this.idsMaterialGenericoToPrint.findIndex(
            (id) => id === item.id_material_generico,
          );
        }
        if (index > -1) {
          if (item.id_material) {
            this.idsMaterialToPrint.splice(index, 1);
          } else {
            this.idsMaterialGenericoToPrint.splice(index, 1);
          }
          this.reportsMateriais.splice(index, 1);

          this.reimpressaoData = {
            ...this.previousReimpressaoData,
            reportsMateriais: [...this.reportsMateriais],
          };
        }
      }
    },
    successZPL() {
      this.zplSuccessPrinter = true;
      this.indexMaterial = 0;
      this.$refs.modalEtiquetaMaterial.hide();
    },
    wantsExit() {
      this.exit = true;
      this.$refs.modalEtiquetaMaterial.hide();
    },
    handleXButton() {
      this.handlerXButton = !this.handlerXButton;
    },
    closingModal() {
      if (
        this.itensToPrint
        && this.itensToPrint.materiais
        && this.itensToPrint.materiais[this.indexMaterial + 1]
        && !this.zplSuccessPrinter
        && !this.exit
      ) {
        this.indexMaterial++;
        this.$refs.modalEtiquetaMaterial.show();
      } else {
        this.exit = false;
        this.indexMaterial = 0;
        this.zplSuccessPrinter = false;
        this.$refs.modalEtiquetaMaterial.hide();
        this.handlerXButton = true;
      }
    },
    showModalReport() {
      this.itensToPrint.materiais = this.materiais.filter((el) => this.idsMaterialToPrint.includes(el.id_material));
      this.setDisabledInputs();
      this.$refs.modalEtiquetaMaterial.show();
    },
  },
};
</script>

<style>
.main {
  width: 100%;
}

.search-icon {
  background-color: white;
  display: inline-block !important;
  border: 1px solid #ced4da;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-right: 3px;
}

.search-input {
  border-right: none;
}

.search-button {
  margin-left: 72.5%;
}

.text {
  font-size: 18px;
}

.printer-button {
  margin-left: 95%;
  padding: none;
  background-color: white;
  border: none;
}

.printer-button:focus {
  outline: 0;
}

.base-header {
  padding: 10px;
}

.pointer {
  cursor: pointer;
}
</style>
